.nav-link {
    color: #1e3547 !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    transition: all 0.5s;
    margin: 0 10px;
}

.nav-link:hover {
    color: #1ea59a !important;
}

.nav-separator {
    width: 100%;
    height: 7px;
    position: relative;
    background-color: #2dca38;
    background: linear-gradient(to right,#1ea59a 0%, #1ea59a 75%, #1ea59a 100%);
    text-shadow: 0 0 1px #000, 0 1px 1px rgb(0 0 0 / 30%);
    font-family: Verdana,sans-serif;
    line-height: 26px;
    color: #fff;
    display: block;
}

.btn-success {
    background: #1ea59a !important;
    border-color: #1ea59a !important;
}

.btn-success:hover {
    background: #21b0a4 !important;
    border-color: #21b0a4 !important;
}

.btn-soft-success {
    background-color: rgba(30, 165, 154, 0.25) !important;
    color: #1ea59a !important;
    transition: all 0.4s;
    font-weight: 600 !important;
}

.accordion-button:focus {
    box-shadow: none !important;
}